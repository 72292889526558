import Swiper, { EffectFade, Thumbs } from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';
import 'swiper/swiper.scss';

Swiper.use([EffectFade, Thumbs]);


onDomReady(() => {
  const container = document.querySelector('[data-product-slider-container]');

  if (container) {
    const galleryThumbs = new Swiper(container.querySelector('[data-product-slider-thumbs]'), {
      centeredSlides: true,
      centeredSlidesBounds: true,
      slidesPerView: 3,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      direction: 'vertical',
      breakpoints: {
        320: {
          spaceBetween: 6,
        },
        768: {
          spaceBetween: 10,
        },
      },
    });

    const galleryMain = new Swiper(container.querySelector('[data-product-slider]'), {
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      preventInteractionOnTransition: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });

    galleryMain.on('slideChangeTransitionStart', () => {
      galleryThumbs.slideTo(galleryMain.activeIndex);
    });

    galleryThumbs.on('transitionStart', () => {
      galleryMain.slideTo(galleryThumbs.activeIndex);
    });
  }
});