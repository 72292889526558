import { onDomReady } from '../../components/dynamic/observer';

class TranslateTitleBySubtitle {
  subtitleHeight = undefined;

  constructor(selector) {
    this.container = selector;
    this.title = this.container.querySelector('[data-translate-title]');
    this.subtitle = this.container.querySelector('[data-translate-subtitle]');

    this.calculateHeight();

    this.bind();
    this.eventListeners();
  }

  bind() {
    this.container.addEventListener('mouseenter', () => {
      this.title.style.transform = `translate3d(0, -${this.subtitleHeight}px, 0)`;
      this.subtitle.style.opacity = '1';
      this.subtitle.style.transform = 'translate3d(0, 0, 0)';
    });
    this.container.addEventListener('mouseleave', () => {
      this.title.style.transform = 'translate3d(0, 0, 0)';
      this.subtitle.style.opacity = '0';
      this.subtitle.style.transform = 'translate3d(0, 30px, 0)';
    });
  }

  eventListeners() {
    window.addEventListener('resize', () => {
      this.calculateHeight();
    })
  }

  calculateHeight() {
    this.subtitleHeight = this.subtitle.getBoundingClientRect().height;
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-translate]')
    .forEach(item => new TranslateTitleBySubtitle(item));
});