import LiveEvent from "../../components/live/live";
import {scrollToTarget} from "../../components/scroll/scroll";

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
  e.preventDefault();
  let target = null;
  if (this.dataset.selector) {
    target = document.querySelector(this.dataset.selector);
  } else {
    target = document.querySelector(this.getAttribute('href'));
  }
  let offset = parseInt(this.dataset.offset) || 0;
  if (target) {
    scrollToTarget(target, offset - 150);
  }
});

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
  e.preventDefault();
  let target = document.querySelector(this.dataset.selector);
  target.classList.toggle(this.dataset.toggle);
});