function initMapGoogle() {
  /*
  let infowindow = new google.maps.InfoWindow({
    content: '<div class="contacts-lines popup">' + $('.contacts-lines').html() + '</div>'
  });
  */

  const customMapType = new google.maps.StyledMapType([
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#000000',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
      ],
    },
  ], {
    name: 'Custom Style',
  });
  const customMapTypeId = 'custom_style';

  document.querySelectorAll('[data-map]').forEach((mapElement) => {
    const lat = parseFloat(mapElement.dataset.lat);
    let lng = parseFloat(mapElement.dataset.lng);
    // Красиво смещаем метку чуть вбок, шоб прям конфета
    if (window.innerWidth >= 1280) {
      lng -= 0.03;
    }
    const map = new google.maps.Map(mapElement, {
      zoom: 13,
      center: {
        lat,
        lng,
      },
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId],
      },
      panControl: false,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false,
    });
    map.mapTypes.set(customMapTypeId, customMapType);
    map.setMapTypeId(customMapTypeId);
    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(mapElement.dataset.lat),
        lng: parseFloat(mapElement.dataset.lng),
      },
      icon: mapElement.dataset.mark,
      map,
    });
  });
  /*
  marker.addListener('click', function() {
    infowindow.open(map, marker);
  });
  */
}

function initMapYandex() {
  document.querySelectorAll('[data-map]').forEach((mapElement) => {
    const myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl'],
    }, {
      searchControlProvider: 'yandex#search',
    });

    const myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: mapElement.dataset.mark,
      // Размеры метки.
      iconImageSize: [43, 57],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-21, -57],
    });

    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
    myMap.geoObjects.add(myPlacemark);
  });
}
// google.maps.event.addDomListener(window, 'load', initMap);

window.addEventListener('load', () => {
  if (window.google) {
    initMapGoogle();
  }
  // if (window.ymaps) {
  //   ymaps.ready(initMapYandex);
  // }
});