import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import _ from 'lodash';
import css from 'nouislider/distribute/nouislider.css';

class RangeInputSlider {
  options = {
    step: 1,
    min: 1,
    max: 10,
    postfix: '',
    value: 1, // single
    from: 1,  // double
    to: 10    // double
  };

  constructor(container, options) {
    container.dataset.sliderActivate = true;
    this.container = container;
    this.init(options);
  }

  init(options) {
    switch (this.container.dataset.type) {
      case 'single':
        this.initElementsForSingle();
        break;
      case 'double':
        this.initElementsForDouble();
        break;
      default:
        throw new Error('Undefined type of range slider');
    }

    this.line = this.container.querySelector('[data-range-line]');
    if (!this.line) {
      throw new Error('Undefined data-range-line element');
    }

    this.options = Object.assign(this.options, this.container.dataset);
    if (options) {
      this.options = Object.assign(this.options, options);
    }

    const createOptions = {
      step: parseInt(this.options.step, 10),
      range: {
        min: parseInt(this.options.min, 10),
        max: parseInt(this.options.max, 10),
      },
      format: wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: this.options.postfix,
      }),
    };

    if (this.isSingle) {
      createOptions.connect = 'lower';
      const initialValue = parseInt(this.options.value, 10);
      if (initialValue) {
        createOptions.start = [initialValue];
      } else {
        createOptions.start = [createOptions.range.min];
      }
    } else {
      createOptions.start = [
        parseInt(this.options.from, 10),
        parseInt(this.options.to, 10),
      ];
      createOptions.connect = true;
    }

    this.slider = noUiSlider.create(this.line, createOptions);
    this.bind();
  }

  initElementsForSingle() {
    this.isSingle = true;
    this.input = this.container.querySelector('[data-range-single-input]');
    this.show = this.container.querySelector('[data-range-single-show]');
  }

  initElementsForDouble() {
    this.isSingle = false;
    this.fromInput = this.container.querySelector('[data-range-from-input]');
    this.toInput = this.container.querySelector('[data-range-to-input]');
    this.fromShow = this.container.querySelector('[data-range-from-show]');
    this.toShow = this.container.querySelector('[data-range-to-show]');
  }

  bind() {
    const instance = this;
    this.slider.on('update', (values, handle) => {
      const event = new Event('change');

      if (instance.isSingle) {
        instance.input.value = values;
        instance.show.innerHTML = values;
        instance.input.dispatchEvent(event);
      } else {
        instance.fromInput.value = values[0];
        instance.toInput.value = values[1];
        instance.fromShow.innerHTML = values[0];
        instance.toShow.innerHTML = values[1];
        instance.fromInput.dispatchEvent(event);
        instance.toInput.dispatchEvent(event);
      }
    });

    const hardSetter = () => {
      if (instance.isSingle) {
        instance.slider.set(instance.input.value);
      } else {
        instance.slider.set([
          instance.fromInput.value,
          instance.toInput.value,
        ]);
      }
    };

    const setter = _.debounce(() => {
      hardSetter();
    }, 1000);

    if (this.isSingle) {
      this.input.addEventListener('keyup', setter);
      this.input.addEventListener('hardSet', hardSetter);
    } else {
      this.fromInput.addEventListener('keyup', setter);
      this.toInput.addEventListener('keyup', setter);
      this.fromInput.addEventListener('hardSet', hardSetter);
      this.toInput.addEventListener('hardSet', hardSetter);

      this.fromInput.dataset.done = 'true';
      this.toInput.dataset.done = 'true';

      this.fromInput.dispatchEvent(new CustomEvent('progressDone'));
      this.toInput.dispatchEvent(new CustomEvent('progressDone'));
    }
  }
}

function initRangeInputSliers() {
  document.querySelectorAll('[data-range-input]:not([data-slider-activate])').forEach((container) => {
    try {
      const rangeInputSliderInstance = new RangeInputSlider(container);
    } catch (e) {
      console.log(e);
    }
  });
}

document.addEventListener('DOMContentLoaded', initRangeInputSliers);
document.addEventListener('DOMContentMutated', initRangeInputSliers);