import {onDomReady} from "../../components/dynamic/observer";

class Cursor {
    cursor = undefined;
    current = undefined;
    move = undefined;
    ease = undefined;
    dragItems = [];
    largeMinWidth = undefined;

    removeClasses = [
        '_drag'
    ];

    constructor() {
        this.move = document.querySelector('[data-cursor]');
        const rootStyles = getComputedStyle(document.documentElement);
        this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1280;

        if (document.body.getBoundingClientRect().width >= this.largeMinWidth) {
            this.cursor = {
                x: 0,
                y: 0,
            };
            this.current = {
                x: 0,
                y: 0,
            };
            this.ease = 0.5;
            this.order = [];
            this.currentState = [];
            this.lastState = [];
            this.itemCollector();

            this.eventListeners();
            this.update();
        } else {
            this.move.classList.add('_hide')
        }
    }

    update() {
        this.lerp();
        this.move.style.transform = `matrix(1, 0, 0, 1, ${this.current.x}, ${this.current.y})`;
        this.defineState();
        const instance = this;
        window.requestAnimationFrame(() => {
            instance.update();
        });
    }

    itemCollector() {
        this.dragItems = document.querySelectorAll('[data-cursor-drag]:not([data-cursor-initialized])');
    }

    lerp() {
        this.current.x = (1 - this.ease) * this.current.x + this.ease * this.cursor.x;
        this.current.y = (1 - this.ease) * this.current.y + this.ease * this.cursor.y;
    }

    defineState() {
        const length = this.order.length;
        if (length) {
            this.lastState = Array.from(this.currentState);
            this.order.forEach((state) => {
                if (state.enter && !this.currentState.find((current) => current === state.name)) {
                    this.currentState.push(state.name);
                } else if (!state.enter) {
                    this.currentState = this.currentState.filter(current => current !== state.name)
                }
            });
            this.currentState = this.currentState.sort();
            this.order = [];
            if (this.currentState !== this.lastState) {
                this.applyClass(this.defineClass());
            }
        }
    }

    defineClass() {
        let className = ''

        if (this.currentState.length) {
            document.body.classList.add('_hide-cursor');

            const isDrag = this.currentState.find((name) => name === 'drag');

            if (isDrag) {
                className = '_drag';
            }

        } else {
            document.body.classList.remove('_hide-cursor');
        }

        return className;
    }

    applyClass(className) {
        const classArray = className.split(' ');
        const removeClasses = this.removeClasses.filter((removeClass) => !classArray.includes(removeClass));
        removeClasses.forEach((removeClass) => this.move.classList.remove(removeClass));
        classArray.forEach((addClass) => {
            if (addClass) {
                this.move.classList.add(addClass);
            }
        });
    }

    initLoadedListeners() {
        this.dragItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseover', () => {
                this.order.push({enter: true, name: 'drag'});
            });
            item.addEventListener('mouseout', () => {
                this.order.push({enter: false, name: 'drag'});
            });
        });
    }

    eventListeners() {
        const instance = this;
        window.addEventListener('mousemove', (e) => {
            instance.cursor.x = e.clientX;
            instance.cursor.y = e.clientY;
        }, false);


        window.addEventListener('cursorMove', (e) => {
            instance.cursor.x = e.detail.offsetX;
            instance.cursor.y = e.detail.offsetY;
        })

        this.initLoadedListeners();

        document.addEventListener('DOMContentMutated', () => {
            this.itemCollector();
            this.initLoadedListeners();
        });
    }
}

onDomReady(() => {
    const follower = new Cursor();
});