/**
 * Нам надо по клику на 1 из элементов списка переключателей:
 * - поменять класс на карточке товаров
 * - поменять класс на всем списке
 * - установить активным перключатель (тот на который нажали)
 *
 * А также при обновлении списка товаров ajax-ом снова установить нужный класс всем кому надо
 * Например, при фильтрации товаров
 *
 * А еще было бы приятно чтобы состояние переключателя сохранялось между страницами
 *
 * То есть, нам надо понимать КАКОЙ класс ставить карточке / списку или еще каким-то объектам
 *
 * .product-card  + .product-card_cell
 *                  .product-card_list
 *
 * .product-list  + .product-list_cell
 *                  .product-list_list
 *
 * Нам не принципиально, чтобы модификаторы элементов, для которых мы переключаем вид отличались,
 * то есть и для списка и для карточки продукта (например)
 * модификатор будет одинаковым (*_cell, *_list, ...)
 *
 * На активном переключателе должен висеть класс _active
 *
 * И еще моментик - а что если на странице таких штук (переключатели + список товаров)
 * будет больше чем одна?
 *
 * Нам надо понимать какие карточки переключаются каким переключателем.
 * Будем оборачивать список с переключателями в data-view-toggle-block
 *
 * Договариваемся, что:
 *
 * data-view-toggle-handle="{Модификатор}"
 *      - переключатель (к любому из них можно добавить data-view-toggle-handle-active,
 *        чтобы выбрать состояние по-умолчанию)
 *
 * data-view-toggle-element="{Класс, к которому надо приклеить модификатор}"
 *      - карточка товара, список, ...
 *
 * data-view-toggle-block="{Имя}"
 *      - Оборачиваем переключатели и список, чтобы связать их друг с другом
 *
 * Например:
 *
 * data-view-toggle-handle="cell" - переключатель в вид "плиточками"
 * data-view-toggle-handle="list" - переключатель в вид "списком"
 * data-view-toggle-element="product-card" - карточка товара
 * data-view-toggle-element="product-list" - список товаров
 *
 * Важно: Ajax-ом может быть добавлен / изменен / удален весь список, включая data-view-toggle-block
 * Надо это учесть.
 */

class ViewToggle {
  constructor(blockElement) {
    this.blockElement = blockElement;
    this.modifiers = [];
    this.handles = blockElement.querySelectorAll('[data-view-toggle-handle]');
    this.activeModifier = this.getActiveModifier();
    this.update();
    this.bind();
  }

  getName() {
    return this.blockElement.viewToggleBlock ? this.blockElement.viewToggleBlock : 'LIST';
  }

  getStorageKey() {
    return `VIEW_TOGGLE_MODE_${this.getName()}`;
  }

  saveActiveModifier() {
    if (localStorage) {
      localStorage.setItem(this.getStorageKey(), this.activeModifier);
    }
  }

  fetchActiveModifier() {
    if (localStorage) {
      return localStorage.getItem(this.getStorageKey());
    }
    return undefined;
  }

  getActiveModifier() {
    this.modifiers = [];
    let activeModifier = null;
    const savedModifier = this.fetchActiveModifier();
    this.handles.forEach((handleElement) => {
      const modifier = handleElement.dataset.viewToggleHandle;
      this.modifiers.push(modifier);
      if (!activeModifier) {
        activeModifier = modifier;
      }
      if (handleElement.dataset.viewToggleHandleActive !== undefined) {
        activeModifier = modifier;
      }
      if (modifier === savedModifier) {
        activeModifier = savedModifier;
      }
    });
    return activeModifier;
  }

  setActiveHandle() {
    this.handles.forEach((handleElement) => {
      if (handleElement.dataset.viewToggleHandle === this.activeModifier) {
        handleElement.classList.add('_active');
      } else {
        handleElement.classList.remove('_active');
      }
    });
  }

  bind() {
    this.handles.forEach((handleElement) => {
      handleElement.addEventListener('click', () => {
        this.activeModifier = handleElement.dataset.viewToggleHandle;
        this.saveActiveModifier();
        this.update();
      });
    });

    document.addEventListener('DOMContentMutated', () => {
      this.update();
    });
  }

  update() {
    this.setActiveHandle();
    this.blockElement.querySelectorAll('[data-view-toggle-element]').forEach((toggleElement) => {
      this.setModifierForElement(toggleElement);
    });
  }

  setModifierForElement(toggleElement) {
    this.modifiers.forEach((modifier) => {
      if (modifier !== this.activeModifier) {
        toggleElement.classList.remove(
          this.buildModifierClass(toggleElement, modifier),
        );
      }
    });
    toggleElement.classList.add(
      this.buildModifierClass(toggleElement, this.activeModifier),
    );
  }

  buildModifierClass(toggleElement, modifier) {
    return `${toggleElement.dataset.viewToggleElement}_${modifier}`;
  }
}

class ViewToggleInitializer {
  constructor(selector) {
    this.selector = selector;
    this.toggles = [];
    this.initToggles();
    this.bind();
  }

  bind() {
    document.addEventListener('DOMContentMutated', () => {
      this.initToggles();
    });
  }

  initToggles() {
    document.querySelectorAll(this.selector)
      .forEach(blockElement => this.initToggle(blockElement));
  }

  initToggle(blockElement) {
    if (blockElement.dataset.initialized) {
      return;
    }
    this.toggles.push(new ViewToggle(blockElement));
    blockElement.dataset.initialized = 'true';
  }
}

new ViewToggleInitializer('[data-view-toggle-block]');