import { onDomReady } from '../../components/dynamic/observer';
const axios = require('axios').default;

onDomReady(() => {
  setTimeout(() => {
    let geoDataJson;
    try {
      geoDataJson = localStorage.getItem('roistat_geo_data');
    } catch (e) {
      console.log(e);
      return;
    }
    const geoData = JSON.parse(geoDataJson);
    if (!geoData.city) {
      return;
    }
    axios.post('/roistat/geo', geoData);
  }, 3000);
});