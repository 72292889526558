import { onDomReady } from '../../components/dynamic/observer';
import timeoutDelay from '../../components/dynamic/timeout-delay';

class Header {
  constructor(element) {
    this.header = element;
    this.hamburger = element.querySelector('[data-menu-button]');
    this.dropMenu = element.querySelector('[data-drop-menu]');
    this.isOpened = false;
    this.currentY = 0;
    this.lastY = 0;
    this.bind();
  }


  bind() {
    this.bindScroll();
    this.bindHamburger();
  }


  bindHamburger() {
    const inctance = this;
    this.hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      inctance.isOpened ? inctance.closeMenu() : inctance.openMenu();
    });
  }

  openMenu() {
    this.header.classList.add('_menu-opened');
    this.isOpened = true;
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  }

  closeMenu() {
    this.header.classList.remove('_menu-opened');
    this.isOpened = false;
    document.body.style.overflow = '';
    document.body.style.height = '';
  }

  bindScroll() {
    const instance = this;
    window.addEventListener('scroll', () => {
      if (window.pageYOffset >= 100) {
        instance.header.classList.add('header_transform');
      } else {
        instance.header.classList.remove('header_transform');
      }
    });
  }
}

onDomReady(() => {
  setTimeout(() => {
    const header = document.querySelector('[data-header]');
    if (header) {
      new Header(header);
    }
  }, timeoutDelay());
});