import { tns } from 'tiny-slider/src/tiny-slider';
import { onDomReady } from '../../components/dynamic/observer';
import bindArrows from './common/bindArrows';

onDomReady(() => {
  const sliderContainer = document.querySelector('[data-product-same-slider-container]');
  if (sliderContainer) {
    const heroSlider = tns({
      container: sliderContainer.querySelector('[data-product-same-slider]'),
      speed: 700,
      controls: false,
      preventActionWhenRunning: true,
      loop: false,
      nav: false,
      responsive: {
        320: {
          items: 2,
          gutter: 8,
        },
        768: {
          items: 3,
          gutter: 12,
        },
        1179: {
          items: 4,
          gutter: 16,
        },
      },
    });

    bindArrows(
      '[data-product-same-slider-left]',
      '[data-product-same-slider-right]',
      heroSlider,
      sliderContainer,
    );
  }
});