import { tns } from 'tiny-slider/src/tiny-slider';
import bindArrows from './common/bindArrows';

const heroSliderContainer = document.querySelector('[data-hero-slider-container]');
if (heroSliderContainer) {
  const heroSlider = tns({
    container: heroSliderContainer.querySelector('[data-hero-slider-image]'),
    speed: 1500,
    controls: false,
    preventActionWhenRunning: true,
  });

  const textSlider = tns({
    container: heroSliderContainer.querySelector('[data-hero-slider-text]'),
    speed: 1500,
    controls: false,
    loop: false,
    preventActionWhenRunning: true,
    touch: false,
    mode: 'gallery',
    nav: false,
  });

  bindArrows(
    '[data-hero-slider-left]',
    '[data-hero-slider-right]',
    heroSlider,
  );

  heroSlider.events.on('transitionStart', () => textSlider.goTo(heroSlider.getInfo().displayIndex - 1));
}