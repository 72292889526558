import { tns } from 'tiny-slider/src/tiny-slider';
import { onDomReady } from '../../components/dynamic/observer';

function initSliders() {
  const progressSliderContainer = document.querySelector('[data-progress-slider-container]');
  if (progressSliderContainer) {
    const progressSlider = tns({
      container: progressSliderContainer.querySelector('[data-progress-slider]'),
      items: 1,
      slideBy: 1,
      nav: false,
      speed: 5000,
      autoplayTimeout: 5000,
      controls: false,
      loop: true,
      mode: 'gallery',
      animateIn: '_animate_in',
      animateOut: '_animate_out',
      animateNormal: '_normal',
      rewind: false,
      autoplay: true,
      autoplayButtonOutput: false,
    });
  }
}

onDomReady(() => { initSliders(); });