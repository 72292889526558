const bindArrows = (left, right, slider, container) => {
  let leftArrows;
  let rightArrows;

  if (!container) {
    leftArrows = document.querySelectorAll(left);
    rightArrows = document.querySelectorAll(right);
  } else {
    leftArrows = container.querySelectorAll(left);
    rightArrows = container.querySelectorAll(right);
  }

  leftArrows.forEach((leftArrow) => {
    leftArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('prev');
      slider.play();
    });
  });

  rightArrows.forEach((rightArrow) => {
    rightArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('next');
      slider.play();
    });
  });
};

export default bindArrows;