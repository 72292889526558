import debounce from 'lodash/debounce';
import Jax from '../../components/jax/jax';

/**
Классическая форма фильтрации.
Можно применять где угодно, в каком угодно проекте, ни к чему не привязана.
На форму вешаем data-filter-form
На все элементы, которые должны изменяться после обработки формы кидаем data-filter-content="NAME"
где NAME - уникальное имя на странице, например PRODUCTS (для списка товаров)
                                                или TOTAL (для количества элементов)
*/

class Filter {
  constructor(formElement, contentElements) {
    this.formElement = formElement;
    this.contentElements = contentElements;
    this.formContainer = this.formElement.closest('.filter-form');

    this.inputs = Array.from(this.formElement.querySelectorAll('input, select'));

    this.openButtons = this.formContainer.querySelectorAll('[data-filter-form-open]');
    this.body = document.body;
    this.formOpened = false;

    this.dummySort = document.querySelector('[data-filter-sort]');
    this.originalSort = formElement.querySelector('[data-sort] select');

    this.countElement = document.querySelector('[data-filter-count]');
    this.bind();
  }

  bind() {
    this.bindOpenable();
    this.bindReset();
    this.bindSort();

    this.formElement.addEventListener('submit', (e) => {
      e.preventDefault();
      this.handleForm();
    });

    const debouncedHandle = debounce(() => {
      this.handleForm();
    }, 50);

    this.inputs.forEach((input) => {
      const isRange = input.dataset.rangeFromInput !== undefined || input.dataset.rangeToInput !== undefined;
      if (!isRange || input.dataset.done === 'true') {
        input.addEventListener('change', debouncedHandle);
        input.addEventListener('input', debouncedHandle);
      } else {
        input.addEventListener('progressDone', () => {
          input.addEventListener('change', debouncedHandle);
          input.addEventListener('input', debouncedHandle);
        });
      }
    });
  }

  bindSort() {
    const instance = this;
    this.dummySort.addEventListener('change', (e) => {
      instance.originalSort.value = instance.dummySort.value;
      instance.originalSort.dispatchEvent(new Event('change'));
    });
  }

  bindOpenable() {
    const instance = this;
    this.openButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();

        if (this.formOpened) {
          instance.close();
        } else {
          instance.open();
        }
      });
    });
  }

  open() {
    this.formOpened = true;
    this.formContainer.classList.add('_opened');
    this.body.style.overflow = 'hidden';
    this.body.style.height = '100%';
  }

  close() {
    this.formOpened = false;
    this.formContainer.classList.remove('_opened');
    this.body.style.overflow = '';
    this.body.style.height = '';
  }

  bindReset() {
    const instance = this;
    const resets = document.querySelectorAll('[data-filter-form-reset]:not([data-initialized="true"])');

    resets.forEach((reset) => {
      reset.dataset.initialized = 'true';

      reset.addEventListener('click', (e) => {
        e.preventDefault();
        instance.inputs.filter(input => input.type === 'checkbox').forEach((input) => {
          input.checked = '';
          input.dispatchEvent(new Event('change'));
        });
      });
    });
  }

  async handleForm() {
    const jax = new Jax();
    const html = await jax.send(new FormData(this.formElement));

    const page = document.createElement('div');
    page.innerHTML = html;

    this.contentElements.forEach((contentElement) => {
      const name = contentElement.dataset.filterContent;
      const newContentElement = page.querySelector(`[data-filter-content="${name}"]`);
      if (newContentElement) {
        contentElement.innerHTML = newContentElement.innerHTML;
      }
      this.bindReset();
    });
    document.dispatchEvent(new Event('DOMContentMutated'));
  }
}

const formElement = document.querySelector('[data-filter-form]');
const contentElements = document.querySelectorAll('[data-filter-content]');

if (formElement) {
  new Filter(formElement, contentElements);
}