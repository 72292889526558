import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { onDomReady } from '../../components/dynamic/observer';

Swiper.use([Pagination]);

onDomReady(() => {
  const container = document.querySelector('[data-giulia-world-history]');

  if (container) {
    const links = container.querySelectorAll('[data-swiper-nav]');

    const count = container.querySelector('[data-giulia]');
    const countSlider = new Swiper(count, {
      slidesPerView: 'auto',
      slideToClickedSlide: true,
      breakpoints: {
        375: {
          spaceBetween: 20,
        },
        768: {
          spaceBetween: 30,
        },
      },
    });

    const sliderElement = container.querySelector('[data-giulia-world-history-slider]');
    const slider = new Swiper(sliderElement, {
      freeMode: true,
      slidesPerView: 'auto',
      preloadImages: false,
    });

    links.forEach((link) => {
      link.addEventListener('click', () => {
        const slideIndex = parseInt(link.dataset.slideIndex, 10);
        slider.slideTo(slideIndex);
      });
    });

    const syncSliders = () => {
      const wrapper = document.querySelector('.history-slider__items-wrap');
      const wrapperBounds = wrapper.getBoundingClientRect();
      const wrapperLeft = Math.round(wrapperBounds.left);
      const wrapperRight = Math.round(wrapperBounds.right);

      let activeSlide = null;
      const activeSlides = [];

      document.querySelectorAll('.history-slider__item')
        .forEach((slide, index) => {
          const slideBounds = slide.getBoundingClientRect();
          const slideRight = Math.round(slideBounds.right);
          const slideLeft = Math.round(slideBounds.left);

          if (slideLeft <= wrapperRight && slideRight >= wrapperLeft) {
            activeSlides.push(index);
            if (activeSlide === null) {
              activeSlide = index;
            }
          }
        });

      links.forEach((link) => {
        link.parentElement.classList.remove('_active');
      });

      activeSlides.forEach((activeSlideIndex) => {
        document.querySelector(`[data-slide-index="${activeSlideIndex}"]`).parentElement.classList.add('_active');
      });

      countSlider.slideTo(activeSlide);
    };

    const setCursorMove = (e) => {
      const event = new CustomEvent('cursorMove', {
        detail: {
          offsetX: e.x,
          offsetY: e.y,
        },
      });
      window.dispatchEvent(event);
    };

    syncSliders();
    slider.on('transitionEnd', syncSliders);
    slider.on('touchEnd', syncSliders);
    slider.on('touchMove', (slider, e) => {
      syncSliders();
      setCursorMove(e);
    });
  }
});