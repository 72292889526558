require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('../components/smart-tabs/smart-tabs');
require('./common/floating-field');
require('../fonts/HelveticaNeueCyr/stylesheet.css');
require('./accordion/initializator');
require('./header/header');
require('./header');
require('./header/drop-menu');

require('fslightbox');

require('./sliders/sliders');
require('./catalog/filter');
require('./catalog/viewToggle');

require('./field/phone.js');
require('./field/range.js'); // Раскоментить при необходимости

require('./common/translate-title-by-subtitle');
require('./cart/cart');

require('./common/cursor');

require('./roistat-geo/index');